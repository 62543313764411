.container-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.slider {
  position: relative;
  width: 100%;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value {
  position: absolute;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 2px;
}

.slider__track {
  background-color: #ced4da;
  width: 100%;
  z-index: 1;
}

.slider__range {
  @apply bg-primary-skyblue;
  z-index: 2;
}

.slider__left-value,
.slider__right-value {
  color: #dee2e6;
  font-size: 12px;
  margin-top: 20px;
}

.slider__left-value {
  left: 6px;
}

.slider__right-value {
  right: -4px;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 100%;
  outline: none;
}


.thumb--zindex-3 {
  z-index: 40;
}

.thumb--zindex-4 {
  z-index: 41;
}

.thumb--zindex-5 {
  z-index: 42;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  border-radius: 50%;
  cursor: pointer;
  pointer-events: all;
  position: relative;
  width: 28px;
  height: 28px;
  margin: 0 96px 0 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #55a9f3;
  border: solid #fff 8px;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  border-radius: 50%;
  cursor: pointer;
  pointer-events: all;
  position: relative;
  width: 28px;
  height: 28px;
  margin: 0 96px 0 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #55a9f3;
  border: solid #fff 8px;
}
