@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .title {
    font-size: theme('fontSize.huge');
    font-weight: theme('fontWeight.bold');
    color: theme('colors.primary-gray');
  }
  .sub-title {
    font-size: theme('fontSize.small');
    font-weight: theme('fontWeight.bold');
    color: theme('colors.primary-gray');
  }
  .sub-span {
    font-size: theme('fontSize.small');
    color: "#A3A3A3";
  }
  .span-link {
    font-size: theme('fontSize.small');
    color: theme('colors.secondary-blue');
    font-weight: 400;
    margin-left: 8px;
    cursor: pointer;
  }

  .bold-text {
    font-weight: theme('fontWeight.bold');
    font-size: theme('fontSize.semilarge');
    line-height: 1.56;
    letter-spacing: -0.02px;
    text-align: left;
    color: theme('colors.trimo-dark');
  }

  .trimo-black-text {
    color: theme('colors.trimo-dark');
    line-height: 1.75;
    letter-spacing: -0.02px;
    text-align: left;
    font-size: theme('fontSize.big');
  }
}